import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_pane = _resolveComponent("pane");
  const _component_Splitpanes = _resolveComponent("Splitpanes");
  return _openBlock(), _createBlock(_component_Splitpanes, {
    class: "default-theme",
    onResize: _cache[0] || (_cache[0] = $event => _ctx.paneSize = $event[0].size)
  }, {
    default: _withCtx(() => [_createVNode(_component_pane, {
      size: $props.left.default,
      "min-size": $props.left.min,
      "max-size": $props.left.max
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "left")]),
      _: 3
    }, 8, ["size", "min-size", "max-size"]), _createVNode(_component_pane, {
      size: $props.right.default,
      "min-size": $props.right.min,
      "max-size": $props.right.max
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "right")]),
      _: 3
    }, 8, ["size", "min-size", "max-size"])]),
    _: 3
  });
}