import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6745a8da"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "map_wrapper"
};
const _hoisted_2 = {
  key: 3,
  class: "fence_icon"
};
const _hoisted_3 = ["title"];
const _hoisted_4 = {
  key: 4,
  class: "fence_icon z_index"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InfoContent = _resolveComponent("InfoContent");
  const _component_BSJMap = _resolveComponent("BSJMap");
  const _component_LiveMapTool = _resolveComponent("LiveMapTool");
  const _component_AlarmMode = _resolveComponent("AlarmMode");
  const _component_Icon = _resolveComponent("Icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BSJMap, {
    ref: "refMap",
    isSatellite: true,
    infoWidth: 340,
    infoHeight: 280,
    class: "map_box",
    onHandleMarkerClick: _ctx.onMarkerClick
  }, {
    window: _withCtx(() => [_ctx.infoContent ? (_openBlock(), _createBlock(_component_InfoContent, {
      key: 0,
      isEmbed: _ctx.isEmbed,
      info: _ctx.infoContent,
      "onAction:gj": _ctx.onGJClick,
      "onAction:detail": _ctx.onDetailClick,
      "onAction:mpttDetail": _ctx.onBMSDetailClick,
      "onAction:dm": _ctx.onDMClick,
      "onAction:cmd": _ctx.onCmdDownClick,
      "onAction:onPanorama": _ctx.onPanoramaClick
    }, null, 8, ["isEmbed", "info", "onAction:gj", "onAction:detail", "onAction:mpttDetail", "onAction:dm", "onAction:cmd", "onAction:onPanorama"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["onHandleMarkerClick"]), _ctx.updateTime && !_ctx.isPanorama ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "updateTime",
    onDblclickPassive: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.updateTime += 30, ["stop"]))
  }, [_createElementVNode("span", null, _toDisplayString(_ctx.updateTime), 1), _createTextVNode("秒后刷新定位 ")], 32)) : _createCommentVNode("", true), !_ctx.isPanorama && !_ctx.isEmbed ? (_openBlock(), _createBlock(_component_LiveMapTool, _mergeProps({
    key: 1,
    class: "LiveMapTool"
  }, _ctx.$attrs), null, 16)) : _createCommentVNode("", true), !_ctx.isEmbed ? (_openBlock(), _createBlock(_component_AlarmMode, {
    key: 2
  })) : _createCommentVNode("", true), !_ctx.isPanorama && !_ctx.isEmbed ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", {
    onClick: _cache[1] || (_cache[1] = $event => {
      _ctx.$emit('openFence');
      _ctx.isRence = !_ctx.isRence;
    }),
    title: !_ctx.isRence ? '打开围栏' : '关闭围栏'
  }, [_createVNode(_component_Icon, {
    class: "cursor-pointer",
    style: {
      "font-size": "32px"
    },
    name: !_ctx.isRence ? 'icon-weilan' : 'Right'
  }, null, 8, ["name"])], 8, _hoisted_3)])) : _createCommentVNode("", true), _ctx.isPanorama ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_Icon, {
    class: "cursor-pointer",
    style: {
      "font-size": "28px"
    },
    name: "SwitchButton",
    onClick: _ctx.onClosePanorman
  }, null, 8, ["onClick"])])) : _createCommentVNode("", true)]);
}