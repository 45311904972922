import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-be9693ac"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "liveside_wrapper"
};
const _hoisted_2 = {
  class: "liveside_organization"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TreeSelect = _resolveComponent("TreeSelect");
  const _component_LiveSideBottom = _resolveComponent("LiveSideBottom");
  const _component_IssuedMode = _resolveComponent("IssuedMode");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_TreeSelect, {
    ref: "refTree",
    treeType: "",
    type: _ctx.dataType,
    isIssued: true,
    checkOnClickLeaf: "",
    isShowStudentNum: "",
    renderAfterExpand: true,
    checkOnClickNode: true,
    onHandleClick: _ctx.deboucneOnClick,
    onHandleIssued: _ctx.deboucneOnIssued,
    onHandleCheck: _ctx.handleCheckChange
  }, null, 8, ["type", "onHandleClick", "onHandleIssued", "onHandleCheck"])]), _createVNode(_component_LiveSideBottom, {
    class: "liveside_student"
  }), _createVNode(_component_IssuedMode, {
    visible: _ctx.issuseVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => _ctx.issuseVisible = $event),
    nodeData: _ctx.nodeData
  }, null, 8, ["visible", "nodeData"])]);
}