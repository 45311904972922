import { hasAuth } from '@/utils/auth';
import { defineAsyncComponent, defineComponent, inject, reactive, toRefs, watch, nextTick } from 'vue';
import { useDebounce } from '@/hooks/core/useDebounce';
import { sleep } from "@/utils/sleep";
export default defineComponent({
  name: 'LiveSide',
  components: {
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue')),
    LiveSideBottom: defineAsyncComponent(() => import('./LiveSideBottom.vue')),
    IssuedMode: defineAsyncComponent(() => import('./IssuedMode.vue'))
  },
  emits: ['openFence'],
  setup(props, {
    emit
  }) {
    const refRoot = inject('rootProvide');
    const refData = reactive({
      refTree: null,
      issuseVisible: false,
      nodeData: null,
      dataType: 3
    });
    function handleClick(node) {
      if (node.type === 'class') {
        //refRoot.filterClassIds = node.id as number;
      }
    }
    function handleIssued(node) {
      if (hasAuth(1201))
        //拥有管理权限，可以发指令
        {
          refData.issuseVisible = true;
          refData.nodeData = node;
        } else {}
    }
    function handleCheckChange(node, checked) {
      nextTick(() => {
        refRoot.pageNo = 1;
        const allCheckeds = refData.refTree.getCheckedNodes() && refData.refTree.getCheckedNodes().filter(p => p.type === 'org');
        refRoot.filterClassIds = allCheckeds.map(p => p.id);
      });
    }
    const [deboucneOnClick] = useDebounce(handleClick, 300);
    const [deboucneOnIssued] = useDebounce(handleIssued, 300);
    // 搜索时,岗位类型变化,设置组织勾选节点
    watch(() => refRoot.currentClassId, async classId => {
      if (classId) {
        var _refRoot$studentList$;
        refRoot.pageNo = 1;
        await sleep(600);
        refData.refTree.filterText = (_refRoot$studentList$ = refRoot.studentList[0]) === null || _refRoot$studentList$ === void 0 ? void 0 : _refRoot$studentList$.className;
        refData.refTree.allOnoff = true;
        refData.refTree.tree.setChecked(classId, true);
        refData.refTree.tree.filter(classId);
      }
    });
    return {
      ...toRefs(refData),
      ...toRefs(refRoot),
      deboucneOnClick,
      deboucneOnIssued,
      handleCheckChange
    };
  }
});