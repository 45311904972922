import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, toRefs, inject, computed, unref, watch, defineAsyncComponent } from 'vue';
import { ElSpace, ElTooltip, ElMessageBox } from 'element-plus';
import { isNullOrUnDef } from '@/utils/is';
import { bdEncrypt } from '@/utils/bdGPS';
import dayjs from 'dayjs';
import { useDebounce } from '@/hooks/core/useDebounce';
import cmdApi from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { useRouter } from 'vue-router';
import BSJMap from '@/components/BSJMap';
import LiveMapTool from './LiveMapTool.vue';
export default defineComponent({
  name: 'LiveMap',
  components: {
    ElSpace,
    ElTooltip,
    BSJMap,
    LiveMapTool,
    InfoContent: defineAsyncComponent(() => import('./LiveInfoWindow.vue')),
    AlarmMode: defineAsyncComponent(() => import('./AlarmMode.vue')),
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue'))
  },
  props: {
    isEmbed: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openFence'],
  setup(props, {
    emit,
    attrs
  }) {
    /**
     * 位置信息更新逻辑
     * 监听数据变化后, 清除之前的位置信息,重新渲染
     */
    const router = useRouter();
    const messageBox = useMessage();
    const refRoot = inject('rootProvide');
    const refData = reactive({
      rfidVueRef: null,
      isRence: false,
      refMap: null,
      infoWindowRef: null,
      refInfo: null,
      isPanorama: false
    });
    // 位置点集合
    let mapClustererMarkers = [];
    // 围栏多边形实例
    let fencePolygon = null;
    // 围栏圆形实例
    let fenceRound = null;
    // 当前勾选的所有员工
    const checkedStudents = computed(() => {
      return refRoot.studentList.filter(student => refRoot.studentCheckedIds.includes(student.objectid));
    });
    // 当前勾选员工的位置点
    const checkedPoints = computed(() => unref(checkedStudents).map(student => [student.lon, student.lat]));
    // 当前选择的员工证
    const currentStudent = computed(() => unref(checkedStudents).find(student => student.objectid === refRoot.currentCardId));
    // 当前选择的员工位置点
    const currentPoint = computed(() => {
      const current = unref(currentStudent);
      return current && [current.lon, current.lat];
    });
    // 信息窗口内容
    const infoContent = computed(() => {
      const current = unref(currentStudent);
      if (isNullOrUnDef(current)) return null;
      return {
        avatarImg: require('@/assets/images/avatar.png'),
        name: current.objectname,
        gender: ['熄火', '启动', ''][current.acc],
        gnss: current.gnss,
        gsm: current.gsm,
        online: current.onlineStatus,
        posType: current.posType,
        deviceType: current.deviceType,
        mdtid: current.mdtid,
        // imei: current.deviceType === 1 ? current.imei : '',
        remainBattery: parseFloat(current.electricity),
        voltage: current.voltage / 10 + 'V',
        posTime: dayjs(current.recvTime).format('YYYY-MM-DD HH:mm:ss'),
        gpsTime: dayjs(current.gpsTime).format('YYYY-MM-DD HH:mm:ss'),
        mileage: current.mileage / 1000 + "km",
        speed: current.speed / 10 + "km/h",
        mdtstatus: current.mdtstatus,
        alarmstatus: current.alarmstatus,
        point: {
          lon: current.lon,
          lat: current.lat
        },
        holdname: current.holdname,
        workMode: current.workMode,
        posTypeStr: current.posTypeStr,
        disturbStatus: current.disturbStatus,
        gradeClass: current.gradeName + current.className,
        alarmTypes: current.alarmList ? current.alarmList.map(p => p.alarmname) : []
      };
    });
    function getMap() {
      return [refData.refMap.getMap(), refData.refMap];
    }
    // 添加聚合点
    async function addMarkers() {
      const [map, refMap] = getMap();
      if (isNullOrUnDef(map)) {
        messageBox.warning('地址加载不全，请刷新页面');
        return false;
      }
      ;
      const students = unref(checkedStudents);
      if (students.length === 0) return;
      students.forEach(student => {
        const point = bdEncrypt([student.lon, student.lat]);
        // 位置标点
        const marker = {
          point: [point.lon, point.lat],
          acc: student.acc,
          info: student
        };
        // const marker = refMap.addPosMarker([point.lon, point.lat], {
        //   sex: student.sex,
        // });
        // map.handleAddEvent(marker, 'click', () => {
        //   onMarkerClick(student);
        // });
        mapClustererMarkers.push(marker);
      });
      refMap.addClusterer(mapClustererMarkers);
    }
    function clearMarkers() {
      const [map, refMap] = getMap();
      refMap.clearClusterer();
      mapClustererMarkers = [];
    }
    // 标记点点击事件
    async function onMarkerClick(student) {
      const [, refMap] = getMap();
      if (refRoot.currentCardId !== student.objectid) {
        // 切换标记点, 并打开窗口
        refRoot.currentCardId = student.objectid;
        return;
      }
      // 标记点已经打开, 则关闭
      if (refMap.isOpenWindow()) {
        return closeInfoWindow();
      }
      openInfoWindow();
    }
    // 打开窗体
    function openInfoWindow() {
      const [map, refMap] = getMap();
      const current = unref(currentStudent);
      if (isNullOrUnDef(current)) {
        return;
      }
      const point = bdEncrypt([current.lon, current.lat]);
      refMap.openInfoWindow({
        lon: point.lon,
        lat: point.lat
      });
    }
    // 关闭窗口
    function closeInfoWindow() {
      const [map, refMap] = getMap();
      refMap.closeInfoWindow();
    }
    // 查看轨迹
    function onGJClick() {
      const current = unref(currentStudent);
      console.log("onGJC" + current.mdtid);
      router.push({
        name: 'Trajectory',
        query: {
          objectid: current.objectid,
          objectname: current.objectname,
          mdtid: current.mdtid,
          v: dayjs().valueOf()
        }
      });
    }
    // 查看详情
    async function onDetailClick() {
      const current = unref(currentStudent);
      refRoot.cardDetailProps.objectid = current.objectid;
      refRoot.cardDetailProps.visible = true;
    }
    // 查看BMS详情
    async function onBMSDetailClick() {
      const current = unref(currentStudent);
      refRoot.mpttDetailProps.objectid = current.objectid;
      refRoot.mpttDetailProps.visible = true;
    }
    // 点名
    async function onDMClick() {
      const current = unref(currentStudent);
      const result = await cmdApi.sendMsg({
        cmdtype: 2,
        mdtid: current.mdtid,
        objectid: current.objectid,
        cmdcontent: ''
      });
      messageBox[result.code == 0 ? 'success' : 'warning'](result.code == 0 ? '指令下发成功' : result.msg);
    }
    // 指令下发
    function onCmdDownClick() {
      const current = unref(currentStudent);
      refRoot.cmdProps.objectid = current.objectid;
      refRoot.cmdProps.visible = true;
    }
    /** 通过已有经纬度 绘制多边形 围栏 */
    function renderPolygon(path) {
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      if (!fencePolygon) {
        initFencePolygon();
      }
      fencePolygon.hide();
      if (isNullOrUnDef(path) || path.length === 0) return;
      const newPath = path.map(({
        lon,
        lat
      }) => {
        const point = bdEncrypt({
          lat,
          lon
        });
        return [point.lon, point.lat];
      });
      map.setZoom(16);
      fencePolygon.setPath(newPath.map(point => map.setPoint(point)));
      fencePolygon.show();
      map.setCenter(newPath[0]);
    }
    /** 通过已有经纬度 绘制圆形 围栏 */
    function renderRound(center, radius) {
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      if (!fenceRound) {
        initFencePolygon(2);
      }
      fenceRound.hide();
      if (isNullOrUnDef(center) || !center) return;
      const {
        lon,
        lat
      } = center;
      const newPath = bdEncrypt({
        lat,
        lon
      });
      map.setZoom(16);
      fenceRound.setCenter(map.setLngLat([newPath.lon, newPath.lat]));
      fenceRound.setRadius(radius);
      fenceRound.show();
      map.setCenter([newPath.lon, newPath.lat]);
    }
    function getMapOptions() {
      const [map, refMap] = getMap();
      const zoom = map.instance.getZoom();
      const bounds = map.instance.getBounds();
      const center = map.instance.getCenter();
      return {
        zoom,
        bounds,
        center: {
          lon: center.lng,
          lat: center.lat
        }
      };
    }
    // 围栏实例
    function initFencePolygon(type = 1) {
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      if (!fencePolygon && type === 1) {
        fencePolygon = map.setPolygon([], {
          strokeColor: '#409EFF',
          strokeWeight: 3,
          strokeOpacity: 1
        });
        fencePolygon.hide();
      } else {
        fenceRound = map.setCircle([114.325878, 23.036363], 0, {
          strokeColor: '#409EFF',
          strokeWeight: 3,
          strokeOpacity: 1
        });
        fenceRound.hide();
      }
    }
    //街景
    function onPanoramaClick() {
      if (refData.isRence) {
        ElMessageBox.confirm(`请先关闭围栏弹框`, '提示', {
          confirmButtonText: '关闭',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          emit('openFence');
          refData.isRence = !refData.isRence;
          const [map, refMap] = getMap();
          const current = unref(currentStudent);
          refData.refMap.handlePanorama({
            lat: current.lat,
            lon: current.lon
          });
          refData.isPanorama = true;
        }).catch(() => {
          // ElMessage.info("已取消退出");
        });
      } else {
        const [map, refMap] = getMap();
        const current = unref(currentStudent);
        refData.refMap.handlePanorama({
          lat: current.lat,
          lon: current.lon
        });
        refData.isPanorama = true;
      }
    }
    //关闭全景
    function onClosePanorman() {
      refData.refMap.onClosePanorman();
      refData.isPanorama = false;
    }
    const [debounceAddMarker] = useDebounce(addMarkers, 100);
    // 勾选的员工位置变化
    watch(checkedPoints, points => {
      clearMarkers();
      if (points.length !== 0) {
        debounceAddMarker();
      }
    });
    // 当前员工位置变化
    watch(currentPoint, point => {
      // 关闭窗体
      if (isNullOrUnDef(point)) {
        closeInfoWindow();
        return;
      }
      openInfoWindow();
    });
    return {
      ...toRefs(refData),
      ...toRefs(refRoot),
      infoContent,
      onGJClick,
      onDetailClick,
      onBMSDetailClick,
      onDMClick,
      onCmdDownClick,
      onPanoramaClick,
      renderPolygon,
      renderRound,
      getMapOptions,
      onClosePanorman,
      onMarkerClick
    };
  }
});