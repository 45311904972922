import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
export default {
  props: {
    left: {
      type: Object,
      default: {
        min: 20,
        max: 20,
        default: 20
      }
    },
    right: {
      type: Object,
      default: {
        min: 70,
        max: 80,
        default: 80
      }
    }
  },
  components: {
    Splitpanes,
    Pane
  },
  setup() {
    return {};
  }
};