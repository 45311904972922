import { defineComponent, reactive, toRefs, computed, nextTick } from 'vue';
import { ElCollapseTransition } from "element-plus";
import { useStore } from "vuex";
export default defineComponent({
  name: 'LiveMapTool',
  emits: ['openFence'],
  components: {
    ElCollapseTransition
  },
  setup() {
    const {
      state
    } = useStore();
    const refData = reactive({
      deptNum: null,
      alarmNum: null,
      alarmDealNum: null,
      objectNum: null,
      allcar: null,
      online: null,
      onoff: false
    });
    const total = computed(() => refData.deptNum || refData.alarmNum || refData.alarmDealNum || refData.allcar || refData.online);
    const userInfo = computed(() => state.userInfo);
    const getTypeNumber = async () => {
      var _userInfo$value;
      const params = {
        holdid: (_userInfo$value = userInfo.value) === null || _userInfo$value === void 0 ? void 0 : _userInfo$value.orgId
      };
      // const { data,msg,code } = await API.getByIdInfo(params);
      // refData.deptNum = data.hasOwnProperty('deptNum')?data.deptcount:null; 
      // refData.alarmNum = data.hasOwnProperty('alarmNum')?data.alarmNum:null;
      // refData.alarmDealNum = data.hasOwnProperty('alarmDealNum')?data.alarmDealNum:null;
      // refData.allcar  = data.hasOwnProperty('allcar')?data.allcar:null;
      // refData.online = data.hasOwnProperty('online')? data.online:null;
      refData.onoff = true;
    };
    nextTick(() => {
      getTypeNumber();
    });
    return {
      ...toRefs(refData),
      total
    };
  }
});